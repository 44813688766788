<template src="./Camera.html"></template>


<script>

import { QrcodeStream } from 'vue-qrcode-reader'
import {mapGetters} from "vuex";
import {TICKET_LEVEL} from "@/core/common";

export default {

  name: "Camera",

  components: {QrcodeStream},

  data: () => ({

    camera: "auto",

    result: null,
    showScanConfirmation: false,

    torchActive: false,
    torchNotSupported: false,

  }),

  created() {

    this.camera = this.getSettings.camera;

  },

  computed:{

    ...mapGetters([
      "getSettings",
      "getTicket",
    ]),

    torch_icon() {
      if (this.torchActive)
        return 'mdi-flashlight'
      else
        return 'mdi-flashlight-off'
    },

    getTicketLevel(){

      //TOTAL_BAD
      let res = {
        bg: "amber lighten-4",
        header: "red darken-2",
        text: "black--text"
      }

      switch (this.getTicket.level){

        case TICKET_LEVEL.TOTAL_BAD:
          res = {
            bg: "amber lighten-4",
            header: "red darken-2",
            text: "black--text"
          }
          break;

        case TICKET_LEVEL.EMULATE_GOOD:
          res = {
            bg: "grey lighten-3",
            header: "blue darken-2",
            text: "black--text"
          }
          if (!this.getTicket.flag.isOriginal){
            res.bg = "amber lighten-2"
          }

          break;

        case TICKET_LEVEL.LOCAL_BASE_GOOD:
          res = {
            bg: "grey darken-3",
            header: "green darken-2",
            text: "white--text"
          }

          if (!this.getTicket.flag.isOriginal){
            res.bg = "amber lighten-2";
            res.text = "black--text";
          }

          break;

        case TICKET_LEVEL.INET_GOOD:
          res = {
            bg: "green lighten-4",
            header: "green darken-2",
            text: "black--text"
          }
          break;


      }

      return res;

    }

  },


  methods: {

    downloadEvent(){
      let id = 498
      this.$store.dispatch('loadEventScan', id);
    },


    prettyJson(json_data){
      return JSON.stringify(json_data, null, 2);
    },

    async onInit (promise) {
      try {
        const { capabilities } = await promise
        this.torchNotSupported = !capabilities.torch
      }
      catch (e) {
        console.error(e)
      }
      finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },

    async onDecode (content) {

      this.pause();

      //console.log(content);
      this.result = content
      //
      // let line = "Театр Самарт ждет вас в гости! IM 489--11353-24702"
      this.$store.dispatch('doScanNew', content);


      // возможно нужно будет обрезать до первого "!"
      //this.result = "Детская картинная галерея! IM 73--11406-29"
      //console.log(this.result);
      // let n = this.result.indexOf("!");
      // let s = this.result.substring(n);

      // this.txt_send_infoline = s.trim();
      // //this.txt_send_infoline = this.result.trim();
      // this.txt_type = "";
      //
      // // распознать qr - разложить его по параметрам
      // // расшифровываем что пришло в qr - помещаем в current_scan_info
      // this.$store.commit('doScan_infoline_suss', this.txt_send_infoline);
      //
      // // найти текущий qr в базе
      // this.$store.commit('doScan_infoline');
      //
      // // отправить данные на сервер
      // this.$store.commit('addScan');



      await this.timeout(500)
      this.unpause()

    },

    unpause () {
      //this.camera = 'auto'
      this.camera = this.camera_default;
      this.showScanConfirmation = false;

    },

    pause () {
      this.showScanConfirmation = true;
      this.camera_default = this.camera;
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },



    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {boundingBox: {x, y, width, height}} = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },

    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {boundingBox, rawValue} = detectedCode

        const centerX = boundingBox.x + boundingBox.width / 2
        const centerY = boundingBox.y + boundingBox.height / 2

        const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)
        console.log(boundingBox.width, ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = "center"

        ctx.lineWidth = 3
        ctx.strokeStyle = '#35495e'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#5cb984'
        ctx.fillText(rawValue, centerX, centerY)
      }
    },

    logErrors(promise) {
      promise.catch(console.error)
    }
  }

}


</script>

<style scoped>

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.6);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.scan-cross {
  position: absolute;
  width: 100%;
  height: 100%;

  /*background-color: rgba(255, 255, 255, .8);*/

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

</style>

